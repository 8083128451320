import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSuccessMsg, successState } from '../store/slices/successSlice';

const ToastButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const successSel = useSelector(successState);

  const handleClick = () => {
    dispatch(setSuccessMsg(null));
    navigate(successSel?.success?.refLink);
  };

  return successSel?.success?.refLink ? (
    <button className="browse-link" onClick={handleClick}>
      {successSel?.success?.label}
    </button>
  ) : (
    <></>
  );
};

export default ToastButton;
