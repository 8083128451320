import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { isTokenPresent } from './services/common.service';
import { userState } from './store/slices/userSlice';
import { clearStorage, getCookie, globalError, setLoader } from './util/util';

const useAuth = () => {
  const dispatch = useDispatch();
  const userSelector = useSelector(userState);
  const location = useLocation();

  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    (async () => {
      const userCookie = getCookie(process.env.REACT_APP_USER);
      const token = userCookie ? JSON.parse(userCookie)?.['authtoken'] : null;

      if (!token) {
        clearStorage();
        setIsAuth(false);
      } else {
        if (userSelector && Object.keys(userSelector ?? {}).length) {
          setIsAuth(true);
        } else {
          await fetchUserDetails(location);
        }
      }
    })();
  }, [isAuth]);

  const fetchUserDetails = async (location) => {
    try {
      setLoader(dispatch, true);
      await isTokenPresent(dispatch, true);
      setIsAuth(true);
    } catch (e) {
      if (location?.state?.from?.pathname == '/signup') {
        let userStored = localStorage?.getItem(process.env.REACT_APP_USER);
        userStored && userStored != null ? setIsAuth(true) : setIsAuth(false);
        return;
      }
      setIsAuth(false);
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  return isAuth;
};

const Protected = () => {
  const location = useLocation();
  const isAuth = useAuth();

  if (isAuth === null) return null;

  if (!isAuth) {
    return (
      <Navigate
        to="/signin"
        data-testid="link"
        replace
        state={{ from: location }}
      />
    );
  }

  return (
    <main className="main-content" data-testid="main-content">
      <Outlet />
    </main>
  );
};

export default Protected;
