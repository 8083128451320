import { Fade, ThemeProvider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Suspense, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingState } from './shared/store/slices/loadingSlice';

import images from './assets/images';
import HandleTimezoneDialog from './components/dialog/handle-timezone-dialog/HandleTimezoneDialog';
import Sidenav from './components/sidenav';
import AppRouting from './shared/AppRouting';
import { isTokenPresent } from './shared/services/common.service';
import ScrollToTop from './shared/shared-comp/ScrollToTop';
import { errorState } from './shared/store/slices/errorSlice';
import {
  setSuccessMsg,
  successState,
} from './shared/store/slices/successSlice';
import { userTimezoneState } from './shared/store/slices/userSlice';
import {
  clearStorage,
  deleteCookie,
  getCookie,
  globalError,
  userSystemTimezone,
} from './shared/util/util';
import { theme } from './theme/theme';
const MemoSidenav = memo(Sidenav);

function App() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const loadingSel = useSelector(loadingState);
  const timezoneSel = useSelector(userTimezoneState);

  const errorMsg = useSelector(errorState);
  const successMsg = useSelector(successState);
  const userCookie = getCookie(process.env.REACT_APP_USER);
  const token = userCookie ? JSON.parse(userCookie)?.['authtoken'] : null;
  const storedItem = localStorage.getItem(process.env.REACT_APP_USER)
    ? JSON.parse(localStorage.getItem(process.env.REACT_APP_USER))
    : null;

  window.onfocus = async () => {
    let location = window.location.pathname;
    if (
      !token &&
      !location.includes('forgot-password') &&
      !location.includes('signup')
    ) {
      await isTokenPresent(dispatch, true);
    }
  };

  useEffect(() => {
    if (errorMsg?.error === 'User not found') {
      if (
        window.location.pathname?.includes('reset-password') ||
        window.location.pathname?.includes('signin') ||
        window.location.pathname?.includes('signup')
      )
        return;

      deleteCookie(process.env.REACT_APP_USER);
      clearStorage();
      window.location.reload();
    }
    if (errorMsg?.error) {
      enqueueSnackbar(errorMsg?.error, {
        variant: 'error',
        TransitionComponent: Fade,
        className: `is-error message alert-message-box bg-white`,
        preventDuplicate: true,
      });
    }
    if (successMsg?.success?.message ?? successMsg?.success) {
      enqueueSnackbar(successMsg?.success?.message ?? successMsg?.success, {
        variant: 'success',
        TransitionComponent: Fade,
        className: `is-success message alert-message-box bg-white`,
        preventDuplicate: true,
      });
    }
    setTimeout(() => {
      globalError(dispatch, '');
      dispatch(setSuccessMsg(null));
    }, 6000);
  }, [errorMsg?.error, successMsg?.success]);

  // ============================================

  return (
    <ThemeProvider theme={theme}>
      <>
        {token && storedItem ? <MemoSidenav /> : null}
        <ScrollToTop />
        {loadingSel?.loading && (
          <div className="B-loading-wrapper">
            <img
              src={(images as any).buAnim}
              alt="B"
              className="builder-logo-img"
            />
          </div>
        )}
        {
          <HandleTimezoneDialog
            {...{
              shouldOpen:
                Boolean(timezoneSel && timezoneSel !== userSystemTimezone()) &&
                getCookie('temp_timezone') !== userSystemTimezone(),
            }}
          />
        }
        <Suspense
          fallback={
            <div className="B-loading-wrapper">
              <img
                src={(images as any).buAnim}
                alt="B"
                className="builder-logo-img"
              />
            </div>
          }
        >
          <AppRouting />
        </Suspense>
      </>
    </ThemeProvider>
  );
}

export default App;
