import instance from '../../shared/interceptor';
import { isTokenPresent } from '../../shared/services/common.service';
import { resetUser } from '../../shared/store/slices/userSlice';
import { SIGNIN, SIGNOUT } from '../../shared/util/constant';
import {
  clearStorage,
  deleteCookie,
  getCookie,
  globalError,
  navigateAccordingToRoleAndPaymentStatus,
  setCookie,
  setLoader,
} from '../../shared/util/util';

export const signInAs = async (
  profile,
  compSel,
  dispatch,
  startTransition,
  navigate,
  from = null
) => {
  let foundUser = compSel?.demo_company_users?.find(
    (mem) => mem?.role_name?.toLowerCase() == profile?.toLowerCase()
  );

  if (foundUser) {
    try {
      let payload = {
        user: {
          email: foundUser?.email,
          password: foundUser?.encrypted_password,
          device_name: navigator?.userAgent,
        },
      };
      let res = (await instance.post(SIGNIN, payload)) as any;
      await logout(dispatch);
      getCompanyDetail(res, dispatch, startTransition, navigate, from, compSel);
    } catch (e: any) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  } else {
    globalError(dispatch, { message: 'User not found.' });
  }
};

const getCompanyDetail = (
  userRes,
  dispatch,
  startTransition,
  navigate,
  from,
  compSel
) => {
  try {
    const userCookie = getCookie(process.env.REACT_APP_USER);
    const studioId = userCookie ? JSON.parse(userCookie)?.['id'] : null;
    setCookie(
      process.env.REACT_APP_USER,
      JSON.stringify({
        authtoken: userRes?.['user']?.['login_token'],
        pm_dashboard_token: userRes?.['user']?.['pm_dashboard_token'],
        id: userRes?.['user']?.['studio_user_pid'],
      })
    );

    setTimeout(async () => {
      isTokenPresent(dispatch, true);
      if (!from) {
        navigateAccordingToRoleAndPaymentStatus(
          navigate,
          compSel,
          userRes['user']
        );
      } else {
        navigate(from);
      }
    }, 10);
  } catch (e: any) {
    globalError(dispatch, e);
  } finally {
    setLoader(dispatch, false);
  }
};

const logout = async (dispatch) => {
  try {
    setLoader(dispatch, true);
    await instance.delete(SIGNOUT);
    deleteCookie(process.env.REACT_APP_USER);
    clearStorage();
    dispatch(resetUser());
  } catch (e) {
    globalError(dispatch, e);
  } finally {
    setLoader(dispatch, false);
  }
};
