import images from '../../assets/images';

// BACKEND ROUTES USED IN SERVICES
export const CONFIRM = 'enterprise/registrations/confirm';
export const SIGNUP = 'enterprise/registrations/signup';
export const FORGOT_PASSWORD = 'enterprise/registrations/forgot_password';
export const VERIFY_RESET_PASSWORD =
  'enterprise/registrations/verify_reset_password_token';
export const RESET_PASS = 'enterprise/registrations/reset_password';
export const SIGNIN = 'enterprise/sessions/signin';
export const SIGNOUT = `enterprise/sessions/signout?device_name=${window.navigator.userAgent}`;
export const SESSION_DETAIL = 'enterprise/sessions/user_details';
export const ENT_USERS = 'enterprise/companies/:id/users';
export const COMPANY = 'enterprise/companies';
export const BULK_UPLOAD = 'enterprise/companies/:id/users/bulk_invite_users';
export const REVOKE_INVITE = 'enterprise/companies/:id/users/:userId';
export const RESEND_INVITE =
  'enterprise/companies/:id/users/:userId/resend_invite';
export const UPDATE_ROLE = 'enterprise/companies/:id/users/:userId/update_role';
export const PLANS = 'enterprise/plans';
export const PLANS_COMPARE_DATA = 'enterprise/plans/compare_data';
export const PURCHASE_ORDER = 'purchase_orders';
export const PENDING_PURCHASE_ORDER = 'pending_purchase_order';
export const RESEND_PURCHASE_ORDER = 'resend';
export const SPACES = 'enterprise/companies/:id/spaces';
export const BULK_CREATE_SPACE = `${SPACES}/bulk_create`;
export const NAV_PANEL = 'enterprise/companies/:id/nav_panel_spaces';
export const ROLES = 'enterprise/companies/:id/roles';
export const LIST_ALL_PERMISSIONS = 'enterprise/companies/:id/permissions';
export const UPDATE_PERMISSIONS =
  'enterprise/companies/:id/roles/:role_id/update_permissions';
export const RESTORE_DEFAULT_PERM =
  'enterprise/companies/:id/roles/:role_id/restore_default';
export const USER_PROFILE = 'enterprise/companies/:id/users/:user_id';
export const UPDATE_PASSWORD = `${USER_PROFILE}/update_password`;
export const REMOVE_FROM_SPACE = `${SPACES}/:space_id/space_members/:space_member`;
export const BULK_CREATE_SPACE_MEMBER = `${SPACES}/:space_id/space_members/bulk_create`;
export const FETCH_USERS_DETAILS =
  'enterprise/companies/:id/users/fetch_details';
export const PROJECTS = `${SPACES}/:space_id`;
export const ENTITY_TAGS = 'enterprise/companies/:id/entity_tags';
export const NOTIFICATION = `${USER_PROFILE}/notification_preferences`;
export const NOTIFICATION_DETAIL = `${USER_PROFILE}/notification_publishes`;
export const USER_SEARCH = `${ENT_USERS}/search`;
export const CONTACT_EXPERT = 'enterprise/registrations/contact_expert';
export const CHANGE_REQUESTS = `${SPACES}/:space_id/build_cards/:build_card_id/change_requests`;
export const PAYMENTS = `${COMPANY}/:id/payments`;
export const PAYMENTS_CSV = `${COMPANY}/:id/export_payments_csv`;
export const CATALOGS = `${COMPANY}/:id/catalogs`;
export const ENT_SIGNED_URL = 'enterprise/attachments/signed_url';

// FRONT END ROUTES USED IN .ts FILES
export const FE_PLAN_CHECKOUT_BILLING = '/plan-checkout/billing-details';
export const ON_BOARDING_OVERVIEW = '/onboarding/owner-overview';
export const ON_BOARDING_WAITING_PAYMENT = '/dashboard';
export const PLANS_CHECKOUT = '/plans/checkout';
export const ON_BOARDING_IT_OVERVIEW = '/onboarding/it-admin-overview';
export const MEMBER_INDEX = '/company/members';
export const MEMBER_DETAIL = '/company/members/:id/member-detail';
export const MEMBER_ONBOARDING = '/member-onboarding';
export const PLAN_PAYMENTS = '/plan-payments';
export const COMPANY_SETTINGS = '/company/company-settings';
export const MEMBER_PROFILE = '/settings/member-profile';
export const COMPANY_SPACE = '/company/spaces';
export const ROLES_PERMISSION = '/company/roles-permissions/default-roles';
export const MEMBER_DASHBOARD = '/onboarding/member-dashboard';
export const BUSINESS_GOALS = '/company/business-goals';
export const COMPANY_INTEGRATION = '/company/integrations';
export const SPACE_LIST = '/company/spaces/all-spaces';
export const SHOW_PLANS = '/show-plans';
export const COMPANY_CREDITS = '/company/company-credits';
export const SPACES_SHOW = '/company/spaces/:id';
export const BUILD_CARDS = `${SPACES_SHOW}/playground/:build_card_id`;
export const CATALOG_INDEX = '/company/catalogue';

// APP CONSTANT DATA
export const AES_KEY = 'c247bb61c24ad241cb60891e717878e6';
export const SALT = 'Rday12Ax3Z9O4VTEDVUFsg==';
export const HEX = 'abcdef9876543210abcdef9876543210';
export const COOKIE_DOMAIN_NAME = '.builder.ai';
export const MAX_NAME_LENGTH = 50;
export const MAX_DESCRIPTION_LENGTH = 200;
export const MAX_CATALOG_DESCRIPTION_LENGTH = 160;

// MEMBER ROLES
export const MEMBER_ROLES = [
  { label: 'CIO', value: 'CIO', key: 'cio' },
  { label: 'IT Admin', value: 'IT Admin', key: 'it' },
  { label: 'Finance Admin', value: 'Finance Admin', key: 'finance' },
  { label: 'Member', value: 'Member', key: 'admin' },
  { label: 'Space Admin', value: 'Space Admin', key: 'space-admin' },
];

// PERMISSIONS OBJECT TO VERIFY USER PERMISSION
export const PERM_OBJ = {
  user: {
    module: 'User',
    read: 'can-read-user',
    update: 'can-update-user',
    create: 'can-create-user',
    destroy: 'can-destroy-user',
  },
  purchase: {
    module: 'Purchase Order',
    read: 'can-read-purchase_order',
    update: 'can-update-purchase_order',
    create: 'can-create-purchase_order',
    destroy: 'can-destroy-purchase_order',
  },
  credit: {
    module: 'Credit',
    read: 'can-read-credit',
    update: 'can-update-credit',
    create: 'can-create-credit',
    destroy: 'can-destroy-credit',
  },
  company: {
    module: 'Company',
    read: 'can-read-company',
    update: 'can-update-company',
    create: 'can-create-company',
    destroy: 'can-destroy-credit',
  },
  role: {
    module: 'Role',
    read: 'can-read-role',
    update: 'can-update-role',
    create: 'can-create-role',
    destroy: 'can-destroy-role',
  },
  space: {
    module: 'Space',
    read: 'can-read-space',
    update: 'can-update-space',
    create: 'can-create-space',
    destroy: 'can-destroy-space',
  },
  space_member: {
    module: 'Space Member',
    read: 'can-read-space_member',
    update: 'can-update-space_member',
    create: 'can-create-space_member',
    destroy: 'can-destroy-space_member',
  },
  project_member: {
    module: 'Project Member',
    read: 'can-read-project_member',
    update: 'can-update-project_member',
    create: 'can-create-project_member',
    destroy: 'can-destroy-project_member',
  },
  project: {
    module: 'Project',
    read: 'can-read-project',
    update: 'can-update-project',
    create: 'can-create-project',
    destroy: 'can-destroy-project',
  },
  buildcard: {
    module: 'Build Card',
    read: 'can-read-build_card',
    update: 'can-update-build_card',
    create: 'can-create-build_card',
    destroy: 'can-destroy-build_card',
    send_for_approval: 'can-send_for_approval-build_card',
    approve: 'can-approve-build_card',
    kickoff: 'can-kickoff-build_card',
    reject: 'can-reject-build_card',
    modify: 'can-modify-build_card',
  },
  payment: {
    module: 'Payment',
    read: 'can-read-payment',
    update: 'can-update-payment',
    create: 'can-create-payment',
    destroy: 'can-destroy-payment',
  },
  rule: {
    module: 'Rule',
    read: 'can-read-rule',
    update: 'can-update-rule',
    create: 'can-create-rule',
    destroy: 'can-destroy-rule',
  },
  integration: {
    module: 'Company Integration',
    read: 'can-read-company_integration',
    update: 'can-update-company_integration',
    create: 'can-create-company_integration',
    destroy: 'can-destroy-company_integration',
  },
  integration_suggestion: {
    module: 'Integration Suggestion',
    read: 'can-read-integration_suggestion',
    update: 'can-update-integration_suggestion',
    create: 'can-create-integration_suggestion',
    destroy: 'can-destroy-integration_suggestion',
  },
  notification: {
    module: 'Notification',
    read: 'can-read-notification',
    update: 'can-update-notification',
    create: 'can-create-notification',
    destroy: 'can-destroy-notification',
  },
  permission: {
    module: 'Permission',
    read: 'can-read-permission',
    update: 'can-update-permission',
    create: 'can-create-permission',
    destroy: 'can-destroy-permission',
  },
  plan: {
    module: 'Plan',
    read: 'can-read-plan',
    update: 'can-update-plan',
    create: 'can-create-plan',
    destroy: 'can-destroy-plan',
  },
  company_goal: {
    module: 'Company Goal',
    read: 'can-read-company_goal',
    update: 'can-update-company_goal',
    create: 'can-create-company_goal',
    destroy: 'can-destroy-company_goal',
  },
  space_goal: {
    module: 'Space Goal',
    read: 'can-read-space_goal',
    update: 'can-update-space_goal',
    create: 'can-create-space_goal',
    destroy: 'can-destroy-space_goal',
  },
  entity_tag: {
    module: 'Entity Tag',
    read: 'can-read-entity_tag',
    update: 'can-update-entity_tag',
    create: 'can-create-entity_tag',
    destroy: 'can-destroy-entity_tag',
  },
  tag: {
    module: 'Tag',
    read: 'can-read-tag',
    update: 'can-update-tag',
    create: 'can-create-tag',
    destroy: 'can-destroy-tag',
  },
  space_credit: {
    module: 'Space Credit',
    read: 'can-read-space_credit',
    update: 'can-update-space_credit',
    destroy: 'can-destroy-space_credit',
  },
  plan_credit_subscription: {
    module: 'Plan Credit Subscription',
    read: 'can-read-plan_credit_subscription',
    update: 'can-update-plan_credit_subscription',
    create: 'can-create-plan_credit_subscription',
    destroy: 'can-destroy-plan_credit_subscription',
  },
  space_goal_buildcard: {
    module: 'Space Goal Buildcard',
    read: 'can-read-space_goal_buildcard',
    update: 'can-update-space_goal_buildcard',
    create: 'can-create-space_goal_buildcard',
    destroy: 'can-destroy-space_goal_buildcard',
  },
  change_request: {
    module: 'Change Request',
    create: 'can-create-change_request',
    read: 'can-read-change_request',
    edit: 'can-edit-change_request',
    update: 'can-update-change_request',
    destroy: 'can-destroy-change_request',
    send_for_internal_approval: 'can-send_for_internal_approval-change_request',
    send_for_delivery_approval: 'can-send_for_delivery_approval-change_request',
    internal_reject: 'can-internal_reject-change_request',
  },
  contact_detail: {
    module: 'Contact Detail',
    read: 'can-read-contact_detail',
    update: 'can-update-contact_detail',
    create: 'can-create-contact_detail',
    destroy: 'can-destroy-contact_detail',
  },
  address: {
    module: 'Address',
    read: 'can-read-address',
    update: 'can-update-address',
    create: 'can-create-address',
    destroy: 'can-destroy-address',
  },
  tax_detail: {
    module: 'Tax Detail',
    read: 'can-read-tax_detail',
    update: 'can-update-tax_detail',
    create: 'can-create-tax_detail',
    destroy: 'can-destroy-tax_detail',
  },
  credits: {
    module: 'Plan Credit Subscription',
    read: 'can-read-plan_credit_subscription',
    update: 'can-update-plan_credit_subscription',
    create: 'can-create-plan_credit_subscription',
    destroy: 'can-destroy-plan_credit_subscription',
  },
  catalog: {
    module: 'Catalog',
    read: 'can-read-catalog',
    update: 'can-update-catalog',
    create: 'can-create-catalog',
    destroy: 'can-destroy-catalog',
    add_to: 'can-add_to-catalog',
  },
  meeting: {
    module: 'Meeting',
    read: 'can-read-meeting',
    update: 'can-update-meeting',
    create: 'can-create-meeting',
    destroy: 'can-destroy-meeting',
  },
  whiteboard: {
    module: 'Whiteboard',
    read: 'can-read-whiteboard',
    update: 'can-update-whiteboard',
    create: 'can-create-whiteboard',
    destroy: 'can-destroy-whiteboard',
  },
};

export const goalStatusList = [
  {
    id: 1,
    text: 'Not Started',
    value: 'not_started',
    color: '#DFE0E6',
    border: '1px solid #DFE0E6',
  },
  {
    id: 3,
    text: 'Progressing',
    value: 'progressing',
    color: '#FFD740',
    border: '1px solid #FFC400',
  },
  {
    id: 2,
    text: 'On Track',
    value: 'on_track',
    color: '#69F0AE',
    border: '1px solid #00E676',
  },
  {
    id: 5,
    text: 'Off Track',
    value: 'off_track',
    color: '#FF8A80',
    border: '1px solid #FFB3AC',
  },
  {
    id: 4,
    text: 'Accomplished',
    value: 'accomplished',
    color: '#00C853',
    border: '1px solid #00C853',
  },
];

export const ROLES_TABLE = [
  {
    title: 'Settings',
    innerTable: [
      {
        description: 'Accesses around profile page of the company.',
        label: 'Company Profile',
        module: 'Company',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description: 'Accesses for plan, billing and payment details.',
        label: 'Plan and Payments',
        module: 'Payment',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description: 'Access to manage the credits for the entire company.',
        label: 'Company credits',
        module: 'Plan Credit Subscription',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description: 'Access to the permissions of system and customer roles.',
        label: 'Roles & Permission',
        module: 'Role',
        view: true,
        update: true,
        destroy: true,
      },
      // {  label: 'Rules & Automation', module: 'Rule', view: true, update: true, destroy: true },
      {
        description:
          'All spaces listing page that includes private and archieved spaces with their respective meta data.',
        label: 'Spaces',
        module: 'Space',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description:
          'All active members within the organisation including their meta data.',
        label: 'Members Listing',
        module: 'User',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description: 'All integration available for the company.',
        label: 'Integrations Directory',
        module: 'Company Integration',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description: 'Access to manage the goals for the entire company.',
        label: 'Company goals',
        module: 'Company Goal',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description: 'Access to view/edit/delete catalogue of the company.',
        label: 'Company Catalogue',
        module: 'Catalog',
        view: true,
        update: true,
        destroy: true,
      },
    ],
  },
  {
    title: 'Spaces',
    innerTable: [
      {
        description:
          'Members working within a given space. Except for CIO, access level is only applicable to roles that owns this entity.',
        label: 'Space Members',
        module: 'Space Member',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description:
          'Access and manage credits allocated to a space. Except for CIO, add/edit/action and delete access level is only applicable if the given role owns this entity.',
        label: 'Space Credits',
        module: 'Space Credit',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description:
          'Access to goals of a given space. Except for CIO, add/edit/action and delete access level is only applicable if the given role owns this entity.',
        label: 'Space Goals',
        module: 'Space Goal',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description:
          'Access to tags of a given space. Except for CIO, add/edit/action and delete access level is only applicable if the given role owns this entity.',
        label: 'Space Tags',
        module: 'Entity Tag',
        view: true,
        update: true,
        destroy: true,
      },
      // { label: 'Space Activity', view: true, update: true, destroy: true },
      {
        description:
          'Access to create and schedule meetings within a space. Members can only delete a meeting created by themselves if delete access is provided.',
        label: 'Space Meetings',
        module: 'Meeting',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description:
          'Access to view, create and manage space whiteboards. Space members can only delete a whiteboard created by themselves, if delete access is provided.',
        label: 'Space Whitebaord',
        module: 'Whiteboard',
        view: true,
        update: true,
        destroy: true,
      },
    ],
  },
  {
    title: 'Buildcards',
    innerTable: [
      {
        description:
          'All Builder that are created by all the spaces. If full access is given the respective role can view/edit,add,action/delete any Buildcard of any space.',
        label: 'Company All Buildcard',
        module: 'Build Card',
        view: true,
        update: true,
        destroy: true,
      },
      {
        description:
          'Permission to send Buildcard for internal approval. Except for CIO, this access level is only applicable if the given role owns the given Space or the Buildcard.',
        label: 'Space Buildcard approval request',
        module: 'Build Card',
        view: false,
        update: true,
        destroy: false,
      },
      {
        description:
          'Permission to approve a Buildcards to be ready for delivery or change in an exisiting Buildcard. Except for CIO, this permission is only applicable if the given role owns the respective Space Buildcard.',
        label: 'Space Buildcard approval',
        module: 'Build Card',
        view: false,
        update: true,
        destroy: false,
      },
      {
        description:
          'Permission to kick off a Buildcard with the delivery squad. Except for CIO, this permission is only applicable if the given role owns the given Space or the Buildcard.',
        label: 'Space Kick off Buildcard',
        module: 'Build Card',
        view: false,
        update: true,
        destroy: false,
      },
      {
        description:
          'Permission to edit the Buildcard name. Except for CIO, this permission is only applicable if the given role owns the given Space or the Buildcard.',
        label: 'Space Buildcard rename',
        module: 'Build Card',
        view: false,
        update: true,
        destroy: false,
      },
      {
        description:
          'Access to promote a Buildcard as template to the catalogue.',
        label: 'Add template to catalogue',
        module: 'Catalog',
        view: false,
        update: true,
        destroy: false,
        to_add: true,
      },
    ],
  },
  // {
  //   title: 'Reports/Analytics',
  //   innerTable: [{ label: 'Budget Analytics' }, { label: 'Space Analytics' }],
  // },
];

// LIST OF COUNTRIES
export const countryList = [
  { code: 'AF', label: 'Afghanistan', dialCode: '+93' },
  { code: 'AX', label: 'Aland Islands', dialCode: '+358' },
  { code: 'AL', label: 'Albania', dialCode: '+355' },
  { code: 'DZ', label: 'Algeria', dialCode: '+213' },
  { code: 'AS', label: 'American Samoa', dialCode: '+1684' },
  { code: 'AD', label: 'Andorra', dialCode: '+376' },
  { code: 'AO', label: 'Angola', dialCode: '+244' },
  { code: 'AI', label: 'Anguilla', dialCode: '+1264' },
  { code: 'AQ', label: 'Antarctica', dialCode: '+672' },
  { code: 'AG', label: 'Antigua And Barbuda', dialCode: '+1268' },
  { code: 'AR', label: 'Argentina', dialCode: '+54' },
  { code: 'AM', label: 'Armenia', dialCode: '+374' },
  { code: 'AW', label: 'Aruba', dialCode: '+297' },
  { code: 'AU', label: 'Australia', dialCode: '+61' },
  { code: 'AT', label: 'Austria', dialCode: '+43' },
  { code: 'AZ', label: 'Azerbaijan', dialCode: '+994' },
  { code: 'BS', label: 'Bahamas', dialCode: '+1242' },
  { code: 'BH', label: 'Bahrain', dialCode: '+973' },
  { code: 'BD', label: 'Bangladesh', dialCode: '+880' },
  { code: 'BB', label: 'Barbados', dialCode: '+1246' },
  { code: 'BY', label: 'Belarus', dialCode: '+375' },
  { code: 'BE', label: 'Belgium', dialCode: '+32' },
  { code: 'BZ', label: 'Belize', dialCode: '+501' },
  { code: 'BJ', label: 'Benin', dialCode: '+229' },
  { code: 'BM', label: 'Bermuda', dialCode: '+1441' },
  { code: 'BT', label: 'Bhutan', dialCode: '+975' },
  { code: 'BO', label: 'Bolivia', dialCode: '+591' },
  { code: 'BA', label: 'Bosnia And Herzegovina', dialCode: '+387' },
  { code: 'BW', label: 'Botswana', dialCode: '+267' },
  { code: 'BV', label: 'Bouvet Island', dialCode: '+47' },
  { code: 'BR', label: 'Brazil', dialCode: '+55' },
  { code: 'IO', label: 'British Indian Ocean Territory', dialCode: '+246' },
  { code: 'BN', label: 'Brunei Darussalam', dialCode: '+673' },
  { code: 'BG', label: 'Bulgaria', dialCode: '+359' },
  { code: 'BF', label: 'Burkina Faso', dialCode: '+226' },
  { code: 'BI', label: 'Burundi', dialCode: '+257' },
  { code: 'KH', label: 'Cambodia', dialCode: '+855' },
  { code: 'CM', label: 'Cameroon', dialCode: '+237' },
  { code: 'CA', label: 'Canada', dialCode: '+1' },
  { code: 'CV', label: 'Cape Verde', dialCode: '+238' },
  { code: 'KY', label: 'Cayman Islands', dialCode: '+1345' },
  { code: 'CF', label: 'Central African Republic', dialCode: '+236' },
  { code: 'TD', label: 'Chad', dialCode: '+235' },
  { code: 'CL', label: 'Chile', dialCode: '+56' },
  { code: 'CN', label: 'China', dialCode: '+86' },
  { code: 'CX', label: 'Christmas Island', dialCode: '+61' },
  { code: 'CC', label: 'Cocos (Keeling) Islands', dialCode: '+61' },
  { code: 'CO', label: 'Colombia', dialCode: '+57' },
  { code: 'KM', label: 'Comoros', dialCode: '+269' },
  { code: 'CG', label: 'Congo', dialCode: '+242' },
  { code: 'CD', label: 'Congo, Democratic Republic', dialCode: '+243' },
  { code: 'CK', label: 'Cook Islands', dialCode: '+682' },
  { code: 'CR', label: 'Costa Rica', dialCode: '+506' },
  { code: 'CI', label: "Cote D'Ivoire", dialCode: '+225' },
  { code: 'HR', label: 'Croatia', dialCode: '+385' },
  { code: 'CU', label: 'Cuba', dialCode: '+53' },
  { code: 'CY', label: 'Cyprus', dialCode: '+357' },
  { code: 'CZ', label: 'Czech Republic', dialCode: '+420' },
  { code: 'DK', label: 'Denmark', dialCode: '+45' },
  { code: 'DJ', label: 'Djibouti', dialCode: '+253' },
  { code: 'DM', label: 'Dominica', dialCode: '+1767' },
  { code: 'DO', label: 'Dominican Republic', dialCode: '+1849' },
  { code: 'EC', label: 'Ecuador', dialCode: '+593' },
  { code: 'EG', label: 'Egypt', dialCode: '+20' },
  { code: 'SV', label: 'El Salvador', dialCode: '+503' },
  { code: 'GQ', label: 'Equatorial Guinea', dialCode: '+240' },
  { code: 'ER', label: 'Eritrea', dialCode: '+291' },
  { code: 'EE', label: 'Estonia', dialCode: '+372' },
  { code: 'ET', label: 'Ethiopia', dialCode: '+251' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', dialCode: '+500' },
  { code: 'FO', label: 'Faroe Islands', dialCode: '+298' },
  { code: 'FJ', label: 'Fiji', dialCode: '+679' },
  { code: 'FI', label: 'Finland', dialCode: '+358' },
  { code: 'FR', label: 'France', dialCode: '+33' },
  { code: 'GF', label: 'French Guiana', dialCode: '+594' },
  { code: 'PF', label: 'French Polynesia', dialCode: '+689' },
  { code: 'TF', label: 'French Southern Territories', dialCode: '+262' },
  { code: 'GA', label: 'Gabon', dialCode: '+241' },
  { code: 'GM', label: 'Gambia', dialCode: '+220' },
  { code: 'GE', label: 'Georgia', dialCode: '+995' },
  { code: 'DE', label: 'Germany', dialCode: '+49' },
  { code: 'GH', label: 'Ghana', dialCode: '+233' },
  { code: 'GI', label: 'Gibraltar', dialCode: '+350' },
  { code: 'GR', label: 'Greece', dialCode: '+30' },
  { code: 'GL', label: 'Greenland', dialCode: '+299' },
  { code: 'GD', label: 'Grenada', dialCode: '+1473' },
  { code: 'GP', label: 'Guadeloupe', dialCode: '+590' },
  { code: 'GU', label: 'Guam', dialCode: '+1671' },
  { code: 'GT', label: 'Guatemala', dialCode: '+502' },
  { code: 'GG', label: 'Guernsey', dialCode: '+44' },
  { code: 'GN', label: 'Guinea', dialCode: '+224' },
  { code: 'GW', label: 'Guinea-Bissau', dialCode: '+245' },
  { code: 'GY', label: 'Guyana', dialCode: '+592' },
  { code: 'HT', label: 'Haiti', dialCode: '+509' },
  { code: 'HM', label: 'Heard Island & Mcdonald Islands', dialCode: '011' },
  { code: 'VA', label: 'Holy See (Vatican City State)', dialCode: '+379' },
  { code: 'HN', label: 'Honduras', dialCode: '+504' },
  { code: 'HK', label: 'Hong Kong', dialCode: '+852' },
  { code: 'HU', label: 'Hungary', dialCode: '+36' },
  { code: 'IS', label: 'Iceland', dialCode: '+354' },
  { code: 'IN', label: 'India', dialCode: '+91' },
  { code: 'ID', label: 'Indonesia', dialCode: '+62' },
  { code: 'IR', label: 'Iran, Islamic Republic Of', dialCode: '+98' },
  { code: 'IQ', label: 'Iraq', dialCode: '+964' },
  { code: 'IE', label: 'Ireland', dialCode: '+353' },
  { code: 'IM', label: 'Isle Of Man', dialCode: '+44' },
  { code: 'IL', label: 'Israel', dialCode: '+972' },
  { code: 'IT', label: 'Italy', dialCode: '+39' },
  { code: 'JM', label: 'Jamaica', dialCode: '+1876' },
  { code: 'JP', label: 'Japan', dialCode: '+81' },
  { code: 'JE', label: 'Jersey', dialCode: '+44' },
  { code: 'JO', label: 'Jordan', dialCode: '+962' },
  { code: 'KZ', label: 'Kazakhstan', dialCode: '+77' },
  { code: 'KE', label: 'Kenya', dialCode: '+254' },
  { code: 'KI', label: 'Kiribati', dialCode: '+686' },
  { code: 'KR', label: 'Korea', dialCode: '+82' },
  { code: 'KW', label: 'Kuwait', dialCode: '+965' },
  { code: 'KG', label: 'Kyrgyzstan', dialCode: '+996' },
  { code: 'LA', label: "Lao People's Democratic Republic", dialCode: '+856' },
  { code: 'LV', label: 'Latvia', dialCode: '+371' },
  { code: 'LB', label: 'Lebanon', dialCode: '+961' },
  { code: 'LS', label: 'Lesotho', dialCode: '+266' },
  { code: 'LR', label: 'Liberia', dialCode: '+231' },
  { code: 'LY', label: 'Libyan Arab Jamahiriya', dialCode: '+218' },
  { code: 'LI', label: 'Liechtenstein', dialCode: '+423' },
  { code: 'LT', label: 'Lithuania', dialCode: '+370' },
  { code: 'LU', label: 'Luxembourg', dialCode: '+352' },
  { code: 'MO', label: 'Macao', dialCode: '+853' },
  { code: 'MK', label: 'Macedonia', dialCode: '+389' },
  { code: 'MG', label: 'Madagascar', dialCode: '+261' },
  { code: 'MW', label: 'Malawi', dialCode: '+265' },
  { code: 'MY', label: 'Malaysia', dialCode: '+60' },
  { code: 'MV', label: 'Maldives', dialCode: '+960' },
  { code: 'ML', label: 'Mali', dialCode: '+223' },
  { code: 'MT', label: 'Malta', dialCode: '+356' },
  { code: 'MH', label: 'Marshall Islands', dialCode: '+692' },
  { code: 'MQ', label: 'Martinique', dialCode: '+596' },
  { code: 'MR', label: 'Mauritania', dialCode: '+222' },
  { code: 'MU', label: 'Mauritius', dialCode: '+230' },
  { code: 'YT', label: 'Mayotte', dialCode: '+262' },
  { code: 'MX', label: 'Mexico', dialCode: '+52' },
  { code: 'FM', label: 'Micronesia, Federated States Of', dialCode: '+691' },
  { code: 'MD', label: 'Moldova', dialCode: '+373' },
  { code: 'MC', label: 'Monaco', dialCode: '+377' },
  { code: 'MN', label: 'Mongolia', dialCode: '+976' },
  { code: 'ME', label: 'Montenegro', dialCode: '+382' },
  { code: 'MS', label: 'Montserrat', dialCode: '+1664' },
  { code: 'MA', label: 'Morocco', dialCode: '+212' },
  { code: 'MZ', label: 'Mozambique', dialCode: '+258' },
  { code: 'MM', label: 'Myanmar', dialCode: '+95' },
  { code: 'NA', label: 'Namibia', dialCode: '+264' },
  { code: 'NR', label: 'Nauru', dialCode: '+674' },
  { code: 'NP', label: 'Nepal', dialCode: '+977' },
  { code: 'NL', label: 'Netherlands', dialCode: '+31' },
  { code: 'AN', label: 'Netherlands Antilles', dialCode: '+599' },
  { code: 'NC', label: 'New Caledonia', dialCode: '+687' },
  { code: 'NZ', label: 'New Zealand', dialCode: '+64' },
  { code: 'NI', label: 'Nicaragua', dialCode: '+505' },
  { code: 'NE', label: 'Niger', dialCode: '+227' },
  { code: 'NG', label: 'Nigeria', dialCode: '+234' },
  { code: 'NU', label: 'Niue', dialCode: '+683' },
  { code: 'NF', label: 'Norfolk Island', dialCode: '+672' },
  { code: 'MP', label: 'Northern Mariana Islands', dialCode: '+1670' },
  { code: 'NO', label: 'Norway', dialCode: '+47' },
  { code: 'OM', label: 'Oman', dialCode: '+968' },
  { code: 'PK', label: 'Pakistan', dialCode: '+92' },
  { code: 'PW', label: 'Palau', dialCode: '+680' },
  { code: 'PS', label: 'Palestinian Territory, Occupied', dialCode: '+970' },
  { code: 'PA', label: 'Panama', dialCode: '+507' },
  { code: 'PG', label: 'Papua New Guinea', dialCode: '+675' },
  { code: 'PY', label: 'Paraguay', dialCode: '+595' },
  { code: 'PE', label: 'Peru', dialCode: '+51' },
  { code: 'PH', label: 'Philippines', dialCode: '+63' },
  { code: 'PN', label: 'Pitcairn', dialCode: '+872' },
  { code: 'PL', label: 'Poland', dialCode: '+48' },
  { code: 'PT', label: 'Portugal', dialCode: '+351' },
  { code: 'PR', label: 'Puerto Rico', dialCode: '+1939' },
  { code: 'QA', label: 'Qatar', dialCode: '+974' },
  { code: 'RE', label: 'Reunion', dialCode: '+262' },
  { code: 'RO', label: 'Romania', dialCode: '+40' },
  { code: 'RU', label: 'Russian Federation', dialCode: '+7' },
  { code: 'RW', label: 'Rwanda', dialCode: '+250' },
  { code: 'BL', label: 'Saint Barthelemy', dialCode: '+590' },
  { code: 'SH', label: 'Saint Helena', dialCode: '+290' },
  { code: 'KN', label: 'Saint Kitts And Nevis', dialCode: '+1869' },
  { code: 'LC', label: 'Saint Lucia', dialCode: '+1758' },
  { code: 'MF', label: 'Saint Martin', dialCode: '+590' },
  { code: 'PM', label: 'Saint Pierre And Miquelon', dialCode: '+508' },
  { code: 'VC', label: 'Saint Vincent And Grenadines', dialCode: '+1784' },
  { code: 'WS', label: 'Samoa', dialCode: '+685' },
  { code: 'SM', label: 'San Marino', dialCode: '+378' },
  { code: 'ST', label: 'Sao Tome And Principe', dialCode: '+239' },
  { code: 'SA', label: 'Saudi Arabia', dialCode: '+966' },
  { code: 'SN', label: 'Senegal', dialCode: '+221' },
  { code: 'RS', label: 'Serbia', dialCode: '+381' },
  { code: 'SC', label: 'Seychelles', dialCode: '+248' },
  { code: 'SL', label: 'Sierra Leone', dialCode: '+232' },
  { code: 'SG', label: 'Singapore', dialCode: '+65' },
  { code: 'SK', label: 'Slovakia', dialCode: '+421' },
  { code: 'SI', label: 'Slovenia', dialCode: '+386' },
  { code: 'SB', label: 'Solomon Islands', dialCode: '+677' },
  { code: 'SO', label: 'Somalia', dialCode: '+252' },
  { code: 'ZA', label: 'South Africa', dialCode: '+27' },
  { code: 'GS', label: 'South Georgia And Sandwich Isl.', dialCode: '+500' },
  { code: 'ES', label: 'Spain', dialCode: '+34' },
  { code: 'LK', label: 'Sri Lanka', dialCode: '+94' },
  { code: 'SD', label: 'Sudan', dialCode: '+249' },
  { code: 'SR', label: 'Suriname', dialCode: '+597' },
  { code: 'SJ', label: 'Svalbard And Jan Mayen', dialCode: '+47' },
  { code: 'SZ', label: 'Swaziland', dialCode: '+268' },
  { code: 'SE', label: 'Sweden', dialCode: '+46' },
  { code: 'CH', label: 'Switzerland', dialCode: '+41' },
  { code: 'SY', label: 'Syrian Arab Republic', dialCode: '+963' },
  { code: 'TW', label: 'Taiwan', dialCode: '+886' },
  { code: 'TJ', label: 'Tajikistan', dialCode: '+992' },
  { code: 'TZ', label: 'Tanzania', dialCode: '+255' },
  { code: 'TH', label: 'Thailand', dialCode: '+66' },
  { code: 'TL', label: 'Timor-Leste', dialCode: '+670' },
  { code: 'TG', label: 'Togo', dialCode: '+228' },
  { code: 'TK', label: 'Tokelau', dialCode: '+690' },
  { code: 'TO', label: 'Tonga', dialCode: '+676' },
  { code: 'TT', label: 'Trinidad And Tobago', dialCode: '+1868' },
  { code: 'TN', label: 'Tunisia', dialCode: '+216' },
  { code: 'TR', label: 'Turkey', dialCode: '+90' },
  { code: 'TM', label: 'Turkmenistan', dialCode: '+993' },
  { code: 'TC', label: 'Turks And Caicos Islands', dialCode: '+1649' },
  { code: 'TV', label: 'Tuvalu', dialCode: '+688' },
  { code: 'UG', label: 'Uganda', dialCode: '+256' },
  { code: 'UA', label: 'Ukraine', dialCode: '+380' },
  { code: 'AE', label: 'United Arab Emirates', dialCode: '+971' },
  { code: 'GB', label: 'United Kingdom', dialCode: '+44' },
  { code: 'US', label: 'United States', dialCode: '+1' },
  { code: 'UM', label: 'United States Outlying Islands', dialCode: '+246' },
  { code: 'UY', label: 'Uruguay', dialCode: '+598' },
  { code: 'UZ', label: 'Uzbekistan', dialCode: '+998' },
  { code: 'VU', label: 'Vanuatu', dialCode: '+678' },
  { code: 'VE', label: 'Venezuela', dialCode: '+58' },
  { code: 'VN', label: 'Viet Nam', dialCode: '+84' },
  { code: 'VG', label: 'Virgin Islands, British', dialCode: '+1284' },
  { code: 'VI', label: 'Virgin Islands, U.S.', dialCode: '+1340' },
  { code: 'WF', label: 'Wallis And Futuna', dialCode: '+681' },
  { code: 'EH', label: 'Western Sahara', dialCode: '+212' },
  { code: 'YE', label: 'Yemen', dialCode: '+967' },
  { code: 'ZM', label: 'Zambia', dialCode: '+260' },
  { code: 'ZW', label: 'Zimbabwe', dialCode: '+263' },
];

export const VAT_AMOUNT = 15000;

export const filterValues = [
  { label: 'Draft' },
  { label: 'Ready for kickoff' },
  { label: 'Pending approval' },
  { label: 'Starting soon' },
  { label: 'Running' },
  { label: 'Delivered' },
];

export const fileExt = [
  'bmp',
  'doc',
  'docx',
  'htm',
  'html',
  'jpg',
  'jpeg',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'tiff',
  'txt',
  'xls',
  'xlsx',
];

export const PlatformAndDevice = [
  {
    id: 11,
    title: 'Android',
    alias_title: 'Android',
    icon: images?.iconAndroid,
  },
  { id: 22, title: 'iOS', alias_title: 'iPhone', icon: images?.iconIOS },
  {
    id: 33,
    title: 'Web',
    alias_title: 'Desktop web app',
    icon: images?.iconWebsite,
  },
  { id: 44, title: 'macOS', alias_title: 'Mac', icon: images?.iconIOS },
  { id: 55, title: 'Windows', alias_title: 'Windows', icon: images?.iconGridB },
  {
    id: 66,
    title: 'watchOS',
    alias_title: 'Apple Watch',
    icon: images?.iconWatchOS,
  },
  {
    id: 77,
    title: 'Natasha',
    alias_title: 'Natasha',
    icon: images?.iconNatasha,
  },
  {
    id: 88,
    title: 'Mobile Site',
    alias_title: 'Mobile web app',
    icon: images?.mobilePhoneLogo,
  },
];

export const COMPARISON_ATTRIBUTES = [
  'name',
  'space',
  'completion',
  'state',
  'platforms',
  'credits',
  'created_at',
  'delivery_date',
  'number_of_journeys',
  'number_of_features',
  'company_goals',
  'creator',
];

// TODO: make this exhaustive
export const statusColorMapping = {
  draft: '#676b7e',
  in_progress: '#6200EA',
  pending_approval: '#FFAB00',
  completed: '#00E676',
  running: '#00c853',
};

export const statusTextAndColor = {
  approval_pending_internal: {
    color: 'yellow',
    text: 'Approval Pending - Internal',
  },
  approval_pending_delivery: {
    color: 'yellow',
    text: 'Approval Pending - Delivery',
  },
  rejected_internal: { color: 'red', text: 'Rejected - Internal' },
  rejected_delivery: { color: 'red', text: 'Rejected - Delivery' },
  draft: { color: 'grey', text: 'Draft' },
};

export const deliverableStats = [
  {
    platforms: null,
    phaseType: '',
    disabledIcon: images.iconProductRoadmapDisabled,
    icon: images.iconProductRoadmap,
    alias: 'Product Roadmap',
    title: 'Product Roadmap',
    description: 'Based on your goals with milestones and release dates.',
    active: false,
  },
  {
    platforms: null,
    phaseType: '',
    disabledIcon: images.iconDesignDisabled,
    icon: images.iconDesign,
    alias: 'design',
    title: 'Design',
    description: "We'll create a custom design and experience.",
    active: false,
  },
  {
    platforms: null,
    phaseType: '',
    disabledIcon: images.iconPrototypeDisabled,
    icon: images.iconPrototype,
    alias: 'professional prototype',
    title: 'Clickable prototype',
    description: 'Get a visual representation of your app to test with users.',
    active: false,
  },
  {
    platforms: null,
    phaseType: '',
    disabledIcon: images.iconBasicBuildDisabled,
    icon: images.iconBasicBuild,
    alias: 'mvp',
    title: 'Basic Build',
    description:
      'This first build (or MVP) will include a minimum set of features. Gain early adopters and gather valuable feedback.',
    active: false,
  },
  {
    platforms: null,
    phaseType: '',
    disabledIcon: images.iconFullBuildDisabled,
    icon: images.iconFullBuild,
    alias: 'full build',
    title: 'Full Build',
    description:
      'We will develop market-ready software for you, including a kick-off with our product team, review sessions, and one year of Studio One support.',
    active: false,
  },
  // {platforms: null, icon: images.iconCustom, disabledIcon: images.iconCustomDisabled, alias: "", title: 'Custom', description: 'Test Environment, Test Environment Test Environment', active: false},
];

export const dateTimePaperPropsStyles = {
  sx: {
    '.MuiPickersCalendarHeader-root': {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
    },
    '.MuiPickersCalendarHeader-root:first-child': {
      order: 0,
      paddingRight: '20px',
      paddingLeft: '20px',
    },
    '.MuiPickersArrowSwitcher-root': {
      display: 'inline-flex',
    },
    '.MuiPickersCalendarHeader-label': {
      textAlign: 'center',
    },
    '.MuiPickersArrowSwitcher-spacer': {
      width: '220px',
    },
    '.css-31ca4x-MuiPickersFadeTransitionGroup-root': {
      display: 'flex',
      position: 'absolute',
      left: '0',
      right: '0',
      margin: '0 auto',
      justifyContent: 'center',
    },
    '.css-9reuh9-MuiPickersArrowSwitcher-root': {
      marginLeft: '-7px',
    },
    '.MuiPickersArrowSwitcher-button': {
      paddingRight: '7px',
    },
    // '.MuiDayCalendar-header': {
    //   fontSize: '12px',
    // },
  },
};
