import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  panelActive: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardSpaceList: (state, action) => {
      return { ...state, dashboardSpaceList: action.payload };
    },
    setPreferences: (state, action) => {
      return { ...state, preferences: action.payload };
    },
    setPanelActive: (state, action) => {
      return { ...state, panelActive: action.payload };
    },
    setTodosReleases: (state, action) => {
      return { ...state, todosReleases: action.payload };
    },
    setChangeRequestDetails: (state, action) => {
      return { ...state, changeRequestDetails: action.payload };
    },
    resetDashboard: () => initialState,
  },
});

export const {
  setDashboardSpaceList,
  setPreferences,
  setPanelActive,
  setTodosReleases,
  setChangeRequestDetails,
  resetDashboard,
} = dashboardSlice.actions;

export const dashboardSpaceListState = (state) =>
  state?.dashboard?.dashboardSpaceList;
export const dashboardPreferenceState = (state) =>
  state?.dashboard?.preferences;
export const panelActiveState = (state) => state?.dashboard?.panelActive;
export const todoReleasesState = (state) => state?.dashboard?.todosReleases;
export const changeRequestDetailState = (state) =>
  state?.dashboard?.changeRequestDetails;

export default dashboardSlice.reducer;
