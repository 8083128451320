import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {
    setGoalsGraphReset: (state, action) => {
      return { goalGraph: action.payload };
    },
    setTimelineGraphReset: (state, action) => {
      return { timelineGraph: action.payload };
    },
    currentGoalDetail: (state, action) => {
      return { ...state, goalDetail: action.payload };
    },
    setGoalListUpdated: (state, action) => {
      return { ...state, goalListUpdated: action.payload };
    },
  },
});

export const {
  setGoalsGraphReset,
  currentGoalDetail,
  setTimelineGraphReset,
  setGoalListUpdated,
} = goalsSlice.actions;

export const goalGraphState = (state) => state.goals?.goalGraph;
export const timelineGraphState = (state) => state.goals?.timelineGraph;
export const currentGoalDetailState = (state) => state?.goals?.goalDetail;
export const goalListUpdatedState = (state) => state?.goals?.goalListUpdated;

export default goalsSlice.reducer;
