import { closeSnackbar, SnackbarProvider } from 'notistack';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import ToastButton from './shared/shared-comp/ToastButton';
import { setSuccessMsg } from './shared/store/slices/successSlice';
import { globalError } from './shared/util/util';

const SnackbarMessagesComp = () => {
  const dispatch = useDispatch();

  const handleClose = (key) => {
    closeSnackbar(key);
    globalError(dispatch, '');
    dispatch(setSuccessMsg(null));
  };

  return (
    <Router
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <SnackbarProvider
        maxSnack={3}
        action={(snackbarKey) => (
          <>
            <ToastButton />
            <span
              className="be-close-dark"
              key={snackbarKey}
              onClick={() => handleClose(snackbarKey)}
            ></span>
          </>
        )}
        key={new Date().getTime().toString()}
        autoHideDuration={4000}
        disableWindowBlurListener={true}
        iconVariant={{
          success: <span className="me-3 toast-message-success"></span>,
          error: <span className="me-3 toast-message-error"></span>,
        }}
      >
        <App />
      </SnackbarProvider>
    </Router>
  );
};

export default SnackbarMessagesComp;
