import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import UnauthorizedAccess from '../components/UnauthorizedAccess';
import { permissionState, userPermissionState } from './store/slices/userSlice';
import { isAllowedTo } from './util/util';

function CanActivateChild({ access, mod, children, entity }) {
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const params = useParams();

  const isHavingAccess = isAllowedTo(
    access,
    mod,
    permissions,
    entity,
    userPermissions
  );

  if (isHavingAccess) {
    return <>{children}</>;
  }

  return <UnauthorizedAccess />;
}

export default CanActivateChild;
