import { lazy } from 'react';

export const NatashaWelcome = lazy(
  () => import('../pages/onboard/NatashaWelcome')
);
export const ProjectSelection = lazy(
  () => import('../pages/onboard/ProjectSelection')
);
export const NatashaRecommendedPlan = lazy(
  () => import('../pages/onboard/NatashaRecommendedPlan')
);
export const ChoosePlan = lazy(
  () => import('../pages/onboard/plans/ChoosePlan')
);
export const OwnerOverview = lazy(
  () => import('../pages/onboard/OwnerOverview')
);
export const ItAdminOverview = lazy(
  () => import('../pages/onboard/ItAdminOverview')
);
export const WaitingOnPayment = lazy(
  () => import('../pages/onboard/WaitingOnPayment')
);
export const MemberDashboard = lazy(
  () => import('../pages/onboard/MemberDashboard')
);

export const CheckoutPlanSelection = lazy(
  () => import('../pages/plan-checkout/CheckoutPlanSelection')
);
export const PlanBillingDetails = lazy(
  () => import('../pages/plan-checkout/PlanBillingDetails')
);
export const PlanPayment = lazy(
  () => import('../pages/plan-checkout/PlanPayment')
);

export const PaymentSuccess = lazy(
  () => import('../pages/payment-success/PaymentSuccess')
);

export const MemberOnboarding = lazy(
  () => import('../pages/member-onboarding/MemberOnboarding')
);
export const NatashaIntro = lazy(
  () => import('../pages/member-onboarding/NatashaIntro')
);
export const ProfileSetup = lazy(
  () => import('../pages/member-onboarding/ProfileSetup')
);
export const ProfilePhoto = lazy(
  () => import('../pages/member-onboarding/ProfilePhoto')
);

export const WorkspaceCustomisation = lazy(
  () => import('../pages/workspace-customisation/WorkspaceCustomisation')
);
export const CompanySetup = lazy(
  () => import('../pages/workspace-customisation/CompanySetup')
);
export const AccountName = lazy(
  () => import('../pages/workspace-customisation/AccountName')
);
export const AccountLogo = lazy(
  () => import('../pages/workspace-customisation/AccountLogo')
);

export const CreditsAndPlan = lazy(
  () => import('../pages/plan-payments/CreditsAndPlan')
);
export const PlanPaymentLogs = lazy(
  () => import('../pages/plan-payments/PlanPaymentLogs')
);
export const PlanPaymentsBillingDetails = lazy(
  () => import('../pages/plan-payments/PlanPaymentsBillingDetails')
);

export const CompanyIntegrations = lazy(
  () => import('../pages/company/CompanyIntegrations')
);
export const CompanyIntegrationsDetail = lazy(
  () => import('../pages/company/CompanyIntegrationsDetail')
);

export const MemberProfile = lazy(
  () => import('../pages/member-profile/MemberProfileNavigation')
);

export const PlaygroundBuildcard = lazy(
  () => import('../pages/building/projects/playground/PlaygroundBuildcard')
);

export const MeetingSummary = lazy(
  () => import('../pages/building/projects/MeetingSummary')
);
export const KpiDetail = lazy(() => import('../pages/company/goals/KpiDetail'));

export const BuildingSpaces = lazy(
  () => import('../pages/building/projects/space-section/BuildingSpaces')
);
export const SpaceOverview = lazy(
  () =>
    import('../pages/building/projects/space-section/overview/SpaceOverview')
);
export const SpaceTeam = lazy(
  () => import('../pages/building/projects/space-section/SpaceTeam')
);
export const SpaceCredits = lazy(
  () => import('../pages/building/projects/space-section/SpaceCredits')
);
export const CompanySettings = lazy(
  () => import('../pages/company/CompanySettings')
);
export const CompanyDefaultRolesPermissions = lazy(
  () => import('../pages/company/CompanyDefaultRolesPermissions')
);
export const CompanyCustomRolesPermissions = lazy(
  () => import('../pages/company/CompanyCustomRolesPermissions')
);
export const MembersDashboard = lazy(
  () => import('../pages/members/MembersDashboard')
);
export const MemberDetail = lazy(() => import('../pages/members/MemberDetail'));

export const SpaceTopNavigation = lazy(
  () => import('../pages/building/HeaderTop')
);

export const DuplicateBuildcard = lazy(
  () => import('../pages/company/duplicate-buildcards/DuplicateBuildcard')
);

export const BusinessGoals = lazy(
  () => import('../pages/company/goals/BusinessGoals')
);
export const BusinessGoalDetails = lazy(
  () => import('../pages/company/goals/BusinessGoalDetails')
);
export const CompanyCredits = lazy(
  () => import('../pages/company/credits/CompanyCredits')
);
export const Checkout = lazy(() => import('../pages/onboard/plans/Checkout'));
export const ForgotPassword = lazy(
  () => import('../pages/pre-login/ForgotPassword')
);
export const SpaceGoalOverview = lazy(
  () => import('../pages/building/goals/SpaceGoalOverview')
);
export const SpaceIdeaboard = lazy(
  () => import('../pages/building/projects/space-section/SpaceIdeaboard')
);
export const PlaygroundMeeting = lazy(
  () => import('../pages/building/projects/playground/PlaygroundMeeting')
);
export const PlaygroundWelcome = lazy(
  () => import('../pages/building/projects/playground/PlaygroundWelcome')
);
export const PlaygroundWhiteboard = lazy(
  () => import('../pages/building/projects/playground/PlaygroundWhiteboard')
);
export const ResetPassword = lazy(
  () => import('../pages/pre-login/ResetPassword')
);
export const Catalog = lazy(() => import('../pages/company/catalog/Catalog'));
export const Signup = lazy(() => import('../pages/pre-login/signup'));
